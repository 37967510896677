.globalCard {
  .bookmark_btn {
    background: transparent;
    border: none;
  }

  .cardEllipsis {
    max-width: 7em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    display: block;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
  }

  &__container {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    padding: 0.5em;
    display: flex;
    position: relative;
  }

  &__left {
    flex: 1 1 80%;
  }

  &__right {
    flex: 1 1 20%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}

.globalCard {
  &__header {
    display: flex;
  }

  &__content {
    display: flex;
    flex-direction: column;
    padding: 0.25em 0.5em;
  }

  &__title {
    font-style: normal;
    font-weight: 600;
    font-family: "Poppins-Medium";
    line-height: normal;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    text-align: left;
  }

  &__desc {
    font-size: 0.8em;
    font-style: normal;
    font-weight: 600;
    font-family: "Poppins-Medium";
    line-height: normal;
    padding: 0.25em 0;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    text-align: left;
  }

  &__contentBtn {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    align-items: center;
  }

  &__btns {
    padding: 0 0.5em;
  }
}
.createdWrap {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 20px 0px;
}
.targetWrap {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.entryWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--color-white);
  margin-bottom: 8px;
}
.stopLossWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.stopLossWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.globalCard {
  &__middle {
    // display: flex;
  }

  &__riskStatus {
    display: flex;
    padding: 0em 1.5em 0.5em 0.5em;

    .long,
    .short {
      display: flex;
      color: #fff;
      background: #009860;
      border-radius: 4px;
      margin-left: 0;
      padding: 0.25em 1em;
      font-size: 0.8em;
      align-items: center;
      min-width: fit-content;
      text-align: center;
      border: none;
      i {
        display: inline-block;
        margin-right: 0.1em;
        position: relative;
        top: 0.3em;
        left: -3px;
      }
    }

    .short {
      background: #ea5954 !important;

      i {
        display: inline-block;
        margin-right: 0.1em;
        position: relative;
        top: -0.1em;
      }
    }

    .expired_btn.mobile-view {
      display: none;
    }

    .update_btn.mobile-view {
      display: none;
    }
  }

  &__riskLabel {
    font-size: 0.8em;
    font-style: normal;
    font-weight: 600;
    font-family: "Poppins-Medium";
    line-height: normal;
    padding: 0 0.5em 0 0;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    text-align: left;
    color: var(--color-white);
  }

  &__riskDesc {
    font-family: "Poppins-Medium";
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    text-align: left;
    margin-left: 15px;
  }
}

.globalCard {
  &__entryStopLoss {
    // display: flex;
    padding: 0.5em;
    justify-content: space-between;
    border-radius: 0px;
    margin: 0.5em 0;
    width: 100%;
    background: rgb(3, 50, 65);
    border-radius: 0px;
    width: calc(100% + 1em);
    margin-left: -0.5em;
  }

  &__entry,
  &__stopLoss {
    display: flex;
    font-size: 0.8em;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 0 0.5em;
  }
  &__stopLoss {
    color: var(--loss-pink);
  }

  &__entryStoplabel {
    font-weight: 500;
    font-family: "Poppins-SemiBold";
  }

  &__stopLossValue {
    display: flex;
  }
}

.globalCard {
  &__bottom {
    // display: flex;
    align-items: center;
    padding: 0 0.5em;
  }

  &__target {
    padding: 0 3em 0 0;
    font-size: 0.8em;
    font-style: normal;
    font-weight: 600;
    font-family: "Poppins-Medium";
    line-height: normal;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    text-align: left;
  }

  &__targetValueWrapper {
    // display: flex;
    flex-wrap: wrap;
    min-height: 3em;
    color: var(--color-white);
  }

  &__targetLabel {
    font-size: 0.8em;
    font-style: normal;
    font-weight: 800;
    padding: 0 1em 0 0;
    line-height: normal;
  }

  &__targetDesc {
    font-size: 0.8em;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  &__targetPercentage {
    font-size: 0.8em;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  &__targetsValPer {
    display: flex;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 10em;
  }

  &__targetValue {
    flex: 1 1 50%;
    display: flex;
    padding: 0.5em 0em;
    align-items: center;
  }

  &__linesWrapper {
    display: flex;
    flex: 1;
    margin: 0;
    padding: 0;
    list-style-type: none;
    list-style: none;
  }

  &__line {
    display: flex;
    height: 100%;
    width: 4.5px;
    margin: 0.4em;
    border-radius: 6.5px;
  }

  &__total {
  }

  &__labelWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    label {
      font-size: 0.8em;
      font-weight: 400;
    }
  }
}

.globalCard {
  &__percentage {
    font-size: 1.25em;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    font-family: Poppins-Medium;
    font-size: 15px;
    font-weight: 500;
    line-height: 22.5px;
    text-align: left;
  }

  &__days {
    font-size: 0.8em;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

.expired_btn {
  color: #fff;
  background: rgba(236, 58, 58, 1);
  display: flex;
  align-items: center;
  padding: 0.4em;
  border-radius: 0.4em;
  font-size: 0.8em;
  border: none;
  img {
    width: 0.8em;
    object-fit: contain;
  }

  span {
    color: #fff !important;
    margin: 0 0.25em;
  }
}

.update_btn {
  color: #fff;
  background: #000;
  display: flex;
  align-items: center;
  padding: 0.4em;
  font-size: 0.8em;
  border-radius: 0.4em;

  img {
    width: 0.8em;
    object-fit: contain;
  }

  span {
    color: #fff !important;
    margin: 0 0.25em;
  }
}
.expGrayOut {
  position: relative;
  pointer-events: none;
  user-select: none;
}

.expGrayOut::before {
  background: url("../../../../public/assets/images/signal_cards/expired.svg");
  content: "Signal Card Expired";
  position: absolute;
  color: #ee4444;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  font-family: "Poppins-Bold";
  font-size: 1em;
  padding: 2em;
  font-weight: 500;
  border-radius: inherit;
  width: 100%;
  height: 100%;
  top: -0.1%;
  margin: 0;
  left: -0.1%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 8%;
  z-index: 3;
  border: 0.07em solid #f85a01;
  pointer-events: none;
}
.selected_card.expGrayOut {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0);
  border: 2px solid transparent;
}
.expGrayOut::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  border-radius: inherit;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.7;
  z-index: 2;
  pointer-events: none;
}

@media screen and (min-width: 1600px) {
  .cardEllipsis {
    max-width: 8em;
  }
}

@media screen and (max-width: 830px) {
  .globalCard {
    &__container {
      max-width: 24em;
      margin: 0.5em;
      padding: 0em;
      flex-direction: column;
    }

    &__header {
      flex-wrap: wrap;
      padding: 1em 1em 1em 0em;
      :nth-child(2) {
        order: 3;
        margin-bottom: 0;
        align-items: self-end;
      }

      :nth-child(3) {
        order: 2;
        margin-bottom: 0;
      }
    }
    &__title {
      font-size: 1.25em;
      padding: 0 0.5em;
      font-weight: 500;
      font-family: "Poppins-SemiBold";
    }
    &__desc {
      font-size: 1.2em;
      padding: 0.5em;
      font-weight: 500;
      font-family: "Poppins-SemiBold";
    }
    &__riskDesc,
    &__riskLabel {
      font-size: 1.1em;
      // padding: 0.5em;
    }
    &__riskLabel {
      letter-spacing: 0.1em;
    }
    &__contentBtn {
      flex-grow: unset;
    }

    &__entry,
    &__stopLoss {
      font-size: 1.2em;
    }
    &__target,
    &__targetLabel,
    &__targetDesc,
    &__targetPercentage {
      font-size: 1.2em;
    }
    &__targetDesc {
      font-weight: 500;
      font-family: "Poppins-SemiBold";
    }
    &__middle {
      flex-wrap: wrap;
    }
    &__percentage {
      font-size: 1.1em;
      letter-spacing: 0;
      font-family: "Poppins-SemiBold";
    }
    &__days {
      font-size: 1em;
      text-align: center;
    }
    &__targetLabel {
      letter-spacing: 0;
      font-family: "Poppins-Bold";
    }
    &__total {
      padding: 1.5em 0 1em 0;
    }
    &__entryStopLoss {
      flex-direction: column;
      width: 100%;
      border-radius: unset;
      padding: 0.5em;
      margin-left: 0px !important;
      width: 100% !important;
    }
    &__riskStatus {
      flex-grow: 1;
      width: 100%;
      padding: 0.25em 1em;
    }
    &__entry,
    &__stopLoss {
      justify-content: space-between;
      padding: 0.25em;
    }

    &__right {
      display: none;
    }

    &__bottom {
      flex-direction: column;
      align-items: start;
      // height: 14.5em;
    }
    &__labelWrapper {
      margin-bottom: 0.5em;
    }
    &__line {
      width: 3.5px;
    }
    &__mobTarget {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      padding: 0.5em 0 1.5em 0;
    }

    &__mobTargetLabels,
    &__mobSignalLabels {
      flex: 1 1 50%;
      height: 100%;
    }

    &__mobSignalLabels {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__mobileright {
      display: flex;
      width: 100%;
    }

    &__targetValueWrapper {
      min-height: auto;
    }

    &__targetValue {
      padding: 0.65em;
    }

    &__target {
      padding: 1em 0.5em;
    }

    &__content {
      flex: 1 1 50%;
    }
  }
  .fullWidthMax {
    max-width: 100% !important;
  }

  .longShortMob {
    flex-grow: 1;
    width: 100%;
    margin-bottom: 0.5em;
    justify-content: space-between;
    min-height: 3em;
  }
  .long,
  .short {
    font-size: 1em !important;
    padding: 0.1em 1.5em !important;
    border: none;
    i {
      padding-right: 0.2em;
      font-size: 0.8em;
    }
  }

  .expired_btn.web-view {
    display: none;
  }

  .update_btn.web-view {
    display: none;
  }

  .expired_btn.mobile-view {
    display: flex !important;
    font-size: 1em;
    img {
      width: 1.2em;
    }
  }

  // .card-ellipsis,
  // span {
  //     max-width: fit-content;
  //     white-space: wrap;
  // }
  .update_btn.mobile-view {
    display: flex !important;
    font-size: 1em;
    img {
      width: 1.2em;
    }
  }

  .trade_order {
    order: 2;
  }
  .trade_btn {
    font-size: 1em !important;
    padding: 0.25em 1.5em;
  }
  .bookmark_btn {
    font-size: 1.5em;
    background: transparent;
    border: none;
  }
  .expGrayOut::before {
    background-size: 30%;
    padding: 57% 0em;
  }
  .cardEllipsis {
    max-width: 9em;
    flex-wrap: nowrap;
  }
}

@media screen and (max-width: 600px) {
  .globalCard {
    &__container {
      max-width: 28em;
      margin: 0.5em;
      padding: 0em;
      flex-direction: column;
    }

    &__header {
      flex-wrap: wrap;
      padding: 1em 1em 1em 0em;
      :nth-child(2) {
        order: 3;
        margin-bottom: 0;
        align-items: self-end;
      }

      :nth-child(3) {
        order: 2;
        margin-bottom: 0;
      }
    }
    &__title {
      font-size: 1.1em;
      padding: 0 0.5em;
      font-weight: 500;
      font-family: "Poppins-SemiBold";
    }
    &__desc {
      font-size: 1.1em;
      padding: 0.5em;
      font-weight: 500;
      font-family: "Poppins-SemiBold";
    }
    &__riskDesc,
    &__riskLabel {
      font-size: 1.1em;
      // padding: 0.5em;
    }
    &__riskLabel {
      letter-spacing: 0.1em;
    }
    &__contentBtn {
      flex-grow: unset;
    }

    &__entry,
    &__stopLoss {
      font-size: 1em;
    }
    &__target,
    &__targetLabel,
    &__targetDesc,
    &__targetPercentage {
      font-size: 1em;
    }
    &__targetDesc {
      font-weight: 500;
      font-family: "Poppins-SemiBold";
    }
    &__middle {
      flex-wrap: wrap;
    }
    &__percentage {
      font-size: 1.2em;
      letter-spacing: 0;
      font-family: "Poppins-SemiBold";
    }
  }
  .fullWidthMax {
    max-width: 100% !important;
  }
}
